import * as React from 'react'
import IndexPage from '../templates/index'
import styled from 'styled-components'

import Layout from 'layouts/main'
import { SCREEN } from 'styles/screens'
import { FixedContainer } from '../components/styled/containers'

const Container = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
  iframe {
    width: 100%;
    height: 700px;
    overflow: hidden;
    border: none;
  }
`

const Verify = () => {
  return (
    <Layout title="Apply" showFooter={false}>
      <Container>
        <FixedContainer style={{ justifyContent: 'center' }}>
          <iframe
            src="https://v7erqg8u3s5.typeform.com/to/M8xIareN"
            title="Apply form"
            frameBorder="none"
            scrolling="no"
          />
        </FixedContainer>
      </Container>
    </Layout>
  )
}

export default Verify
